import React, { useState, useEffect } from 'react';
import './App.css';

const STATUS_WAITING = (
  <svg
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    className="lds-rolling"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      ng-attr-stroke="{{config.color}}"
      ng-attr-stroke-width="{{config.width}}"
      ng-attr-r="{{config.radius}}"
      ng-attr-stroke-dasharray="{{config.dasharray}}"
      stroke="#009FDB"
      strokeWidth="10"
      r="35"
      strokeDasharray="164.93361431346415 56.97787143782138"
      transform="rotate(317.238 50 50)"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        calcMode="linear"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
        dur="1s"
        begin="0s"
        repeatCount="indefinite"
      ></animateTransform>
    </circle>
  </svg>
);
const STATUS_NO_FILE = 'THE LOONEY TUNE TRACK YOU\'RE LOOKING FOR HAS EXPIRED.';
const STATUS_OK = 'YOUR LOONEY TUNE TRACK HAS BEEN EMAILED TO YOU.';
const STATUS_UNK = 'THE LOONEY TUNE TRACK YOU\'RE LOOKING FOR HAS EXPIRED.';

function App() {
  const [status, setStatus] = useState(STATUS_WAITING);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const email = urlParams.get('d');
    const id = urlParams.get('id');

    if (!id || !email) {
      console.log('No id or email provided.');
      return;
    }

    invokeLambdaAPI(email, id)
      .catch((error) => {
        console.err(error);
      })
      .then((response) => {
        response.json().then(res => {
          if (res.statusCode === 404 || res.statusCode === 500) {
            setStatus(STATUS_NO_FILE);
            return;
          }
          // Timeout is treated as "OK"
          if (res.statusCode === 504 || 200) {
            setStatus(STATUS_OK);
            return;
          }
          setStatus(STATUS_UNK);
        });
      })
      .catch(err => setStatus(STATUS_UNK));
  }, []);

  return (
    <div className='App'>
      <header className='head' />
      <div className='main'>
        {status === STATUS_WAITING ? (
          <p className='hint'>
            Preparing
            <br />
            Email …
          </p>
        ) : (
          ''
        )}
        {status}
      </div>
      <footer className='foot' />
    </div>
  );
}

const invokeLambdaAPI = (emailAddress, uuid) => {
  // Lambda API generates another email that has an attached video this tim.
  console.log('Sending request to API Gateway endpoint.');
  return fetch(process.env.REACT_APP_LambdaApiUrl, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_LambdaApiKey,
    },
    body: JSON.stringify({
      email: emailAddress,
      uuid,
      attachment: 'true',
    }),
  });
};

export default App;
